<template>
    <div>
        <v-sheet class="dense-inputs">
            <v-row no-gutters>
                <div class="d-flex flex-row align-center pt-3 flex-md-wrap">
                    <v-btn
                        :disabled="selectedBundles.length === 0"
                        class="px-2"
                        @click="openMassEditBundleCostDialog()"
                    >
                        <v-icon color="black lighten-1" small class="mr-1">edit</v-icon>
                        <span v-if="filterOptions.type === 'NSV'">{{ $t('message.updateBundlesCost') }}</span>
                        <span v-if="filterOptions.type === 'SV'">{{ $t('message.updatePalletsCost') }}</span>
                    </v-btn>
                    <v-btn
                        :disabled="selectedBundles.length === 0"
                        class="px-2 ml-2"
                        @click="openMassEditBundleDialog()"
                    >
                        <v-icon color="black lighten-1" small class="mr-1">edit</v-icon>
                        <span v-if="filterOptions.type === 'NSV'">{{ $t('message.updateBundlesSpecs') }}</span>
                        <span v-if="filterOptions.type === 'SV'">{{ $t('message.updatePalletsSpecs') }}</span>
                    </v-btn>
                    <v-select
                        :items="filteredOffices"
                        :loading="loading.filter.offices"
                        :placeholder="$t('message.warehouse')"
                        autocomplete="off"
                        class="filter-width-85 ml-2"
                        dense
                        hide-details="auto"
                        item-value="Office.country_id"
                        item-text="Office.code"
                        solo
                        v-model="filterOptions.office"
                        @change="warehouseChanged()"
                    />
                    <v-select
                        :items="types"
                        :placeholder="$t('message.type')"
                        autocomplete="off"
                        class="filter-width-125 ml-2"
                        dense
                        hide-details="auto"
                        solo
                        v-model="filterOptions.type"
                        @change="filterChanged('types')"
                    />
                    <v-text-field
                        :placeholder="$t('message.poBundle')"
                        :value="searchTerm"
                        class="filter-width-150 force-text-left ml-2"
                        clearable
                        dense
                        hide-details="auto"
                        append-icon="search"
                        solo
                        @change="searchTerm = $event"
                    />
                    <v-autocomplete
                        :items="stockSuppliers"
                        :loading="loading.filter.suppliers"
                        :placeholder="$t('message.spl')"
                        autocomplete="off"
                        class="filter-width-100 ml-2"
                        clearable
                        dense
                        hide-details="auto"
                        item-value="Supplier.id"
                        item-text="Supplier.title"
                        solo
                        v-model="filterOptions.supplier"
                        @change="filterChanged('suppliers')"
                    />
                    <v-autocomplete
                        :items="stockProducts"
                        :loading="loading.filter.products"
                        :placeholder="$t('message.product')"
                        autocomplete="off"
                        class="filter-width-150 ml-2"
                        clearable
                        dense
                        hide-details="auto"
                        item-value="Productgroup.id"
                        item-text="Productgroup.title"
                        solo
                        v-model="filterOptions.product"
                        @change="filterChanged('products')"
                    />
                    <v-autocomplete
                        :items="stockSpecies"
                        :loading="loading.filter.species"
                        :placeholder="$t('message.species')"
                        autocomplete="off"
                        class="filter-width-130 ml-2"
                        clearable
                        dense
                        hide-details="auto"
                        item-value="Species.id"
                        item-text="Species.title"
                        solo
                        v-model="filterOptions.species"
                        @change="filterChanged('species')"
                    />
                    <v-autocomplete
                        :items="stockClaims"
                        :loading="loading.filter.claim"
                        :placeholder="$t('message.certification')"
                        autocomplete="off"
                        class="filter-width-130 ml-2"
                        clearable
                        dense
                        hide-details="auto"
                        item-value="Certification.id"
                        item-text="Certification.title"
                        solo
                        v-model="filterOptions.claim"
                        @change="filterChanged('claims')"
                    />
                    <v-btn :loading="loading.reset" class="ml-2" @click="resetFilters()">{{ $t('message.clear') }}</v-btn>
                    <v-btn class="ml-2" @click="openTotalsDialog()">{{ $t('message.showTotals') }}</v-btn>
                </div>
                <v-spacer/>
                <ExportTableJson
                    :export-conditions="exportConditions"
                    :export-data="selectedBundles.length > 0 ? selectedBundles : Stocks"
                    :export-fields="[]"
                    :export-source="exportSource"
                />
            </v-row>
            <v-row no-gutters v-if="false">
                <div class="d-flex flex-row align-center pt-3 flex-md-wrap">
                    <v-select
                        :items="valueOptions"
                        :placeholder="$t('message.value')"
                        autocomplete="off"
                        dense
                        hide-details="auto"
                        solo
                        style="max-width: 190px !important;"
                        v-model="filterOptions.value"
                        @change="filterChanged('values')"
                    />
                    <div class="d-flex flex-row pt-1 align-center ml-3">
                        <div class="font-sm">
                            <v-icon small color="orange lighten-1">fiber_manual_record</v-icon>{{ $t('message.reducedBookValue') }}
                        </div>
                        <div class="font-sm ml-3">
                            <v-icon small color="red darken-1">fiber_manual_record</v-icon>{{ $t('message.writtenOff') }}
                        </div>
                    </div>
                </div>
            </v-row>
        </v-sheet>
        <v-overlay :value="loading.stocks" absolute opacity="0.15">
            <v-row>
                <v-col class="text-center">
                    <v-progress-circular color="primary" indeterminate size="40" width="6"/>
                </v-col>
            </v-row>
        </v-overlay>
        <v-data-table
            :footer-props="{
                itemsPerPageOptions: [10,20,50,-1],
                showCurrentPage: true,
                showFirstLastPage: true
            }"
            :height="tableHeight"
            :headers="headers"
            :items="Stocks"
            :items-per-page="-1"
            :search="searchTerm"
            :options.sync="tableOptions"
            :sort-by="'Stock.age'"
            calculate-widths
            class="mt-3 appic-table-light specification-table"
            dense
            disable-pagination
            fixed-header
            hide-default-footer
            id="stocksTable"
            item-key="Stock.id"
            show-select
            v-model="selectedBundles"
        >
            <template v-slot:top>
                <div class="d-flex flex-row pt-0 pb-2 align-center">
                    <div class="font-sm">
                        <v-icon small color="orange lighten-1">fiber_manual_record</v-icon>{{ $t('message.reducedBookValue') }}
                    </div>
                    <div class="font-sm ml-3">
                        <v-icon small color="red darken-1">fiber_manual_record</v-icon>{{ $t('message.writtenOff') }}
                    </div>
                </div>
            </template>
            <template v-slot:item.Stock.value_reduced="{ item }">
                <v-icon small color="orange lighten-1" v-if="parseInt(item.Stock.value_reduced) === 2">fiber_manual_record</v-icon>
                <v-icon small color="red darken-1" v-if="parseInt(item.Stock.value_reduced) === 3">fiber_manual_record</v-icon>
            </template>
            <template v-slot:item.Stock.StockOrder.title="{ item }">
                <div class="font-sm pt-2">{{ item.Stock.StockOrder.title }}</div>
            </template>
            <template v-slot:item.Stock.StockOrder.Supplier.code="{ item }">
                <div class="font-sm pt-2">{{ item.Stock.StockOrder.Supplier.code }}</div>
            </template>
            <template v-slot:item.Stock.StockOrder.volume="{ item }">
                <div class="font-sm pt-2">{{ item.Stock.StockOrder.volume }}</div>
            </template>
            <template v-slot:item.Stock.StockOrder.container="{ item }">
                <div class="font-sm pt-2">{{ item.Stock.StockOrder.container }}</div>
            </template>
            <template v-slot:item.Stock.id="{ item }">
                <div class="text-center">
                    <v-menu>
                        <template v-slot:activator="{ on: menu }">
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on: tooltip }">
                                    <v-btn icon v-on="{ ...tooltip, ...menu}" class="ml-1">
                                        <v-icon>more_vert</v-icon>
                                    </v-btn>
                                </template>
                                <span>{{ $t('message.moreActions') }}</span>
                            </v-tooltip>
                        </template>
                        <v-list dense>
                            <v-list-item class="font-sm" @click="openEditBundleDialog(item.Stock.id, item.Stock.bundleno)">
                                <v-icon color="black lighten-1" small class="mr-1">edit</v-icon>
                                <span v-if="filterOptions.type === 'NSV'">{{ $t('message.updateBundle') }}</span>
                                <span v-if="filterOptions.type === 'SV'">{{ $t('message.updatePallet') }}</span>
                            </v-list-item>
                            <v-list-item class="font-sm" @click="openSplitReBundleDialog(item.Stock.id, item.Stock.bundleno)">
                                <v-icon small class="mr-1" color="green">fas fa-divide</v-icon>
                                <span v-if="filterOptions.type === 'NSV'">{{ $t('message.splitBundle') }}</span>
                                <span v-if="filterOptions.type === 'SV'">{{ $t('message.splitPallet') }}</span>
                            </v-list-item>
<!--                            <v-list-item class="font-sm" @click="updateStockOrder(item.Stock.id, item.Stock.StockOrder.id)" v-if="item.Stock.StockOrder.id != null"><v-icon small class="mr-1">edit</v-icon>{{ $t('message.updateStockPo') }}</v-list-item>-->
                            <v-list-item class="font-sm" @click="openDeleteBundleDialog(item.Stock.id, item.Stock.bundleno)"><v-icon small class="mr-1" color="red lighten-1">cancel</v-icon>
                                <span v-if="filterOptions.type === 'NSV'">{{ $t('message.deleteBundle') }}</span>
                                <span v-if="filterOptions.type === 'SV'">{{ $t('message.deletePallet') }}</span>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </div>
            </template>
            <template v-slot:item.Contract.etadate="{ item }">
                <div class="font-sm pt-2">{{ formatDate(item.Contract.etadate) }}</div>
            </template>
            <template v-slot:item.Stock.StockOrder.stockindate="{ item }">
                <div class="font-sm pt-2">{{ formatDate(item.Stock.StockOrder.stockindate) }}</div>
            </template>
            <template v-slot:item.Stock.age="{ item }">
                <div class="font-sm pt-2" v-if="item.Stock.age < 180">{{ item.Stock.age }}</div>
                <div class="font-sm pt-2 red--text text--darken-3" v-else>180+</div>
            </template>
            <template v-slot:item.Stock.bundleno="{ item }">
                <div class="font-sm font-weight-bold pt-2 text-no-wrap d-flex flex-row align-center" v-if="filterOptions.type === 'NSV'">
                    <span>{{ item.Stock.bundleno }}</span>
                    <v-icon small color="pink lighten-3" v-if="item.Stock.hasCosting === false">fiber_manual_record</v-icon>
                </div>
                <div class="font-sm font-weight-bold pt-2 text-no-wrap d-flex flex-row align-center" v-if="filterOptions.type === 'SV'">
                    <span>{{ item.Stock.wh_pallet_no }}</span>
                    <v-icon small color="pink lighten-3" v-if="item.Stock.hasCosting === false">fiber_manual_record</v-icon>
                </div>
            </template>
            <template v-slot:item.Species.title="{ item }">
                <div class="font-sm pt-2">{{ item.Species.title }}</div>
            </template>
            <template v-slot:item.Product.title="{ item }">
                <div class="font-sm pt-2">{{ item.Product.title }}</div>
            </template>
            <template v-slot:item.SalesDescription.title="{ item }">
                <div class="font-sm pt-2 text-no-wrap truncate">{{ item.SalesDescription.title }}</div>
            </template>
            <template v-slot:item.Grade.title="{ item }">
                <div class="font-sm pt-2">{{ item.Grade.title }}</div>
            </template>
            <template v-slot:item.Stock.certification="{ item }">
                <div class="font-sm pt-2">{{ item.Stock.certification }}</div>
            </template>
            <template v-slot:item.Stock.thickness="{ item }">
                <div class="font-sm pt-2 text-no-wrap">{{ item.Stock.thickness }}</div>
            </template>
            <template v-slot:item.Stock.width="{ item }">
                <div class="font-sm pt-2">{{ item.Stock.width }}</div>
            </template>
            <template v-slot:item.Stock.length="{ item }">
                <div class="font-sm pt-2">{{ item.Stock.length }}</div>
            </template>
            <template v-slot:item.Stock.piece="{ item }">
                <div class="font-sm pt-2 text-right" v-if="filterOptions.type === 'NSV'">{{ item.Stock.piece }}</div>
                <div class="font-sm pt-2 text-right" v-if="filterOptions.type === 'SV'">{{ item.Stock.sheets }}</div>
            </template>
            <template v-slot:item.Stock.volume="{ item }">
                <div class="font-sm pt-2 text-no-wrap text-right">{{ formatThisNumber(item.Stock.volume, item.Soldmeasurement.precision) }}</div>
            </template>
            <template v-slot:item.Soldmeasurement.title="{ item }">
                <div class="font-sm pt-2 text-right">{{ item.Soldmeasurement.title }}</div>
            </template>
            <template v-slot:item.Stock.StockOrder.Quantity.uofm="{ item }">
                <div class="font-sm pt-2 text-no-wrap text-left">{{ item.Stock.StockOrder.Quantity.uofm }}</div>
            </template>
            <template v-slot:item.Stock.costprice="{ item }">
                <div class="font-sm pt-2 text-no-wrap text-right">{{ formatThisNumber(item.Stock.costprice, findCurrencyPrecision(item.Contract.currency_id)) + " " + currency(item.Contract.currency_id) }}</div>
            </template>
            <template v-slot:item.Stock.costPriceLocal="{ item }">
                <div class="font-sm pt-2 text-no-wrap text-right">{{ formatThisNumber(item.Stock.costPriceLocal, findCurrencyPrecision(item.Stock.StockOrder.localCurrencyId)) + " " + currency(item.Stock.StockOrder.localCurrencyId) }}</div>
            </template>
            <template v-slot:item.Stock.costPriceCurrent="{ item }">
                <div class="font-sm pt-2 text-no-wrap text-right">{{ formatThisNumber(item.Stock.costPriceCurrent, findCurrencyPrecision(item.Contract.currency_id)) + " " + currency(item.Contract.currency_id) }}</div>
            </template>
            <template v-slot:item.Stock.costPriceCurrentLocal="{ item }">
                <div class="font-sm pt-2 text-no-wrap text-right">{{ formatThisNumber(item.Stock.costPriceCurrentLocal, findCurrencyPrecision(item.Stock.StockOrder.localCurrencyId)) + " " + currency(item.Stock.StockOrder.localCurrencyId) }}</div>
            </template>
        </v-data-table>
        <DeleteBundle
            :bundle-id="selectedBundleId"
            :bundle-no="selectedBundleNo"
            :dialog.sync="deleteDialog"
            :type="filterOptions.type"
            @dialog-closed="deleteDialogClosed"
            @delete-done="bundleDeleted"
        />
        <UpdateBundleV2
            :bundle-id="selectedBundleId"
            :bundle-no="selectedBundleNo"
            :dialog.sync="editDialog"
            :type="filterOptions.type"
            @dialog-closed="editDialogClosed"
            @update-done="bundleUpdated"
        />
        <MassUpdateBundleCost
            :bundles="selectedBundles"
            :dialog.sync="massEditCostDialog"
            :type="filterOptions.type"
            @dialog-closed="massEditCostDialogClosed"
            @update-done="bundlesCostUpdated"
        />
        <MassUpdateBundleV2
            :bundles="selectedBundles"
            :dialog.sync="massEditDialog"
            :type="filterOptions.type"
            @dialog-closed="massEditDialogClosed"
            @update-done="bundlesUpdated"
        />
        <SplitBundle
            :bundle-id="selectedBundleId"
            :bundle-no="selectedBundleNo"
            :dialog.sync="splitDialog"
            :type="filterOptions.type"
            @dialog-closed="splitDialogClosed"
            @split-done="bundleSplit"
        />
        <SimpleAlert
            :alert_message.sync="dialogs.error_message"
            :dialog.sync="dialogs.error"
            @dialog-closed="dialogClosed"
        />
        <MasterStockListTotals
            :dialog.sync="totalsDialog"
            :totals="totalVolumes"
            @dialog-closed="totalsDialogClosed"
        />
    </div>
</template>

<script>
    import {formatDate, numberFormat, capitalize} from "Helpers/helpers";
    import SimpleAlert from "Components/Appic/SimpleAlert";
    import {mapGetters, mapActions} from "vuex";
    import {mapFields} from "vuex-map-fields";
    import {api} from "Api";

    const ExportTableJson = () => import("Components/Appic/ExportTableJson");
    const MassUpdateBundleCost = () => import("Components/Appic/MassUpdateBundleCost");
    const MassUpdateBundleV2 = () => import("Components/Appic/MassUpdateBundleV2");
    const MasterStockListTotals = () => import("Components/Appic/MasterStockListTotals.vue");
    const SplitBundle = () => import("Components/Appic/SplitBundle");
    const UpdateBundleV2 = () => import("Components/Appic/UpdateBundleV2");
    const DeleteBundle = () => import("Components/Appic/DeleteBundle");

    export default {
        name: "StocksListingV2",
        components: {
            DeleteBundle,
            ExportTableJson,
            MassUpdateBundleV2,
            MassUpdateBundleCost,
            MasterStockListTotals,
            SimpleAlert,
            SplitBundle,
            UpdateBundleV2
        },
        title: '',
        data() {
            return {
                bundleTitle: this.$t('message.bundle'),
                deleteDialog: null,
                dialogs: {
                    error: false,
                    error_message: ""
                },
                editDialog: null,
                exportConditions: {},
                exportSource: 'stocks',
                filterOptions: {
                    claim: null,
                    office: 1,
                    product: null,
                    species: null,
                    supplier: null,
                    type: 'NSV',
                    value: null
                },
                hideDefaultFooter: true,
                loading: {
                    fetch: false,
                    filter: {
                        office: false,
                        claim: false,
                        species: false,
                        suppliers: false,
                        products: false
                    },
                    filterResults: false,
                    reset: false,
                    stocks: false
                },
                massEditDialog: false,
                massEditCostDialog: false,
                piecesTitle: this.$t('message.pcs'),
                Stocks: [],
                searchField: null,
                searchTerm: null,
                selectedBundles: [],
                selectedBundleId: null,
                selectedBundleNo: '',
                splitDialog: null,
                stockClaims: [],
                stockProducts: [],
                stockSpecies: [],
                stockSuppliers: [],
                tableHeight: 500,
                tableFooterHeight: 0,
                tableOptions: {
                    page: 1
                },
                types: [
                    {value: 'NSV', text: this.$t('message.others')},
                    {value: 'SV', text: this.$t('message.slicedVeneer')}
                ],
                totalsDialog: false,
                totalVolumes: [],
                valueOptions: [
                    {value: 2, text: this.$t('message.reducedBookValue')},
                    {value: 3, text: this.$t('message.writtenOff')}
                ]
            }
        },
        computed: {
            ...mapFields('runtime',{
                openedTabs: 'openedTabs',
                stockCurrentWarehouse: 'stockCurrentWarehouse',
                stockTypeCurrentWarehouse: 'stockTypeCurrentWarehouse',
            }),
            ...mapGetters('currency',{
                allCurrencies: 'allCurrencies'
            }),
            ...mapGetters('user',{
                User: 'User'
            }),
            ...mapGetters([
                'offices'
            ]),
            cssVars() {
                return {
                    '--height': this.tableFooterHeight.toString() + 'px'
                }
            },
            filteredOffices () {
                return this.offices.filter( o => [1,21].includes(o.Office.id))
            },
            headers () {
                return [
                    {
                        id: 1,
                        text: this.$t('message.actions'),
                        value: 'Stock.id',
                        class: 'light-green lighten-3 pa-1 width-1-pct',
                        sortable: false,
                        searchable: false
                    },
                    {
                        id: 2,
                        text: '',
                        value: 'Stock.value_reduced',
                        class: 'light-green lighten-3 pa-1 width-1-pct',
                        sortable: true,
                        searchable: false
                    },
                    {
                        id: 3,
                        text: this.$t('message.po'),
                        value: 'Stock.StockOrder.title',
                        class: 'light-green lighten-3 pa-1 width-10-pct'
                    },
                    {
                        id: 4,
                        text: this.$t('message.supplier'),
                        value: 'Stock.StockOrder.Supplier.code',
                        class: 'light-green lighten-3 pa-1'
                    },
                    {
                        id: 5,
                        text: this.$t('message.etaWh'),
                        value: 'Stock.StockOrder.stockindate',
                        class: 'light-green lighten-3 pa-1'
                    },
                    {
                        id: 6,
                        text: capitalize(this.$t('message.days')),
                        value: 'Stock.age',
                        class: 'light-green lighten-3 pa-1',
                        sortable: true,
                        searchable: true
                    },
                    {
                        id: 7,
                        text: this.$t('message.certification'),
                        value: 'Stock.certification',
                        class: 'light-green lighten-3 pa-1 text-no-wrap'
                    },
                    {
                        id: 8,
                        text: this.bundleTitle,
                        value: 'Stock.bundleno',
                        class: 'light-green lighten-3 pa-1 text-no-wrap',
                        sortable: true
                    },
                    {
                        id: 9,
                        text: this.$t('message.product'),
                        value: 'Product.title',
                        class: 'light-green lighten-3 pa-1 text-no-wrap width-10-pct',
                        sortable: true
                    },
                    {
                        id: 10,
                        text: this.$t('message.species'),
                        value: 'Species.title',
                        class: 'light-green lighten-3 pa-1 text-no-wrap',
                        sortable: true
                    },
                    {
                        id: 11,
                        text: this.$t('message.grade'),
                        value: 'Grade.title',
                        class: 'light-green lighten-3 pa-1 text-no-wrap'
                    },
                    {
                        id: 12,
                        text: 'T',
                        value: 'Stock.thickness',
                        class: 'light-green lighten-3 pa-1 text-no-wrap'
                    },
                    {
                        id: 13,
                        text: 'W',
                        value: 'Stock.width',
                        class: 'light-green lighten-3 pa-1 text-no-wrap'
                    },
                    {
                        id: 14,
                        text: 'L',
                        value: 'Stock.length',
                        class: 'light-green lighten-3 pa-1 text-no-wrap'
                    },
                    {
                        id: 15,
                        text: this.piecesTitle,
                        value: 'Stock.piece',
                        class: 'light-green lighten-3 pa-1 text-no-wrap force-text-right',
                        sortable: false
                    },
                    {
                        id: 16,
                        text: this.$t('message.quantity'),
                        value: 'Stock.volume',
                        class: 'light-green lighten-3 pa-1 text-no-wrap force-text-right',
                        sortable: false
                    },
                    {
                        id: 17,
                        text: this.$t('message.unit'),
                        value: 'Soldmeasurement.title',
                        class: 'light-green lighten-3 pa-1 text-no-wrap text-right',
                        sortable: false
                    }
                ]
            },
            searchFields() {
                return this.headers.filter((header)=>header.searchable === true)
            },
        },
        methods: {
            ...mapActions('currency',{
                getAllCurrencies: 'getAllCurrencies',
            }),
            ...mapActions('stock',{
                // deleteStockById: 'deleteStockById',
                getAllStocks: 'getAllStocks',
            }),
            bundleDeleted() {
                this.deleteDialog = false;
                this.loadStocks()
            },
            bundleSplit() {
                this.splitDialog = false;
                this.loadStocks()
            },
            bundleUpdated () {
                this.editDialog = false;
                this.loadStocks()
            },
            bundlesUpdated() {
                this.massEditDialog = false;
                this.selectedBundles = []
                this.loadStocks()
            },
            bundlesCostUpdated() {
                this.massEditCostDialog = false;
                this.selectedBundles = []
                this.loadStocks()
            },
            currency(currency_id) {
                const currency = this.allCurrencies.find((currency) => currency.Currency.id === currency_id)
                if(currency){
                    return currency.Currency.code
                }
                return ''
            },
            deleteDialogClosed() {
                this.deleteDialog = false;
            },
            dialogClosed () {
                this.dialogs.error = false;
                this.dialogs.error_message = ''
            },
            editDialogClosed () {
                this.editDialog = false;
            },
            filterChanged (filter) {
                switch(filter){
                    case 'suppliers':
                        this.loading.stocks = true
                        this.loading.fetch = true
                        this.loadStockFilterOptions('products')
                            .then((products) => {
                                this.stockProducts = products
                                this.loadStockFilterOptions('species')
                                    .then((species) => {
                                        this.stockSpecies = species
                                        this.loadStockFilterOptions('claims')
                                            .then((claims) => {
                                                this.stockClaims = claims
                                                this.loadStocks()
                                            })
                                            .catch((error) => {
                                                this.$toast.error(this.$t('message.error') + ': ' + error,
                                                    {
                                                        classes: ['icon-float-left'],
                                                        icon: 'error_outline'
                                                    }
                                                )
                                            })
                                    })
                                    .catch((error) => {
                                        this.$toast.error(this.$t('message.error') + ': ' + error,
                                            {
                                                classes: ['icon-float-left'],
                                                icon: 'error_outline'
                                            }
                                        )
                                    })
                            })
                            .catch((error) => {
                                this.$toast.error(this.$t('message.error') + ': ' + error,
                                    {
                                        classes: ['icon-float-left'],
                                        icon: 'error_outline'
                                    }
                                )
                            })
                        break;
                    case 'species':
                        this.loading.stocks = true
                        this.loading.fetch = true
                        this.loadStockFilterOptions('products')
                            .then((products) => {
                                this.stockProducts = products
                                this.loadStockFilterOptions('suppliers')
                                    .then((suppliers) => {
                                        this.stockSuppliers = suppliers
                                        this.loadStockFilterOptions('claims')
                                            .then((claims) => {
                                                this.stockClaims = claims
                                                this.loadStocks()
                                            })
                                            .catch((error) => {
                                                this.$toast.error(this.$t('message.error') + ': ' + error,
                                                    {
                                                        classes: ['icon-float-left'],
                                                        icon: 'error_outline'
                                                    }
                                                )
                                            })
                                    })
                                    .catch((error) => {
                                        this.$toast.error(this.$t('message.error') + ': ' + error,
                                            {
                                                classes: ['icon-float-left'],
                                                icon: 'error_outline'
                                            }
                                        )
                                    })
                            })
                            .catch((error) => {
                                this.$toast.error(this.$t('message.error') + ': ' + error,
                                    {
                                        classes: ['icon-float-left'],
                                        icon: 'error_outline'
                                    }
                                )
                            })
                        break;
                    case 'products':
                        this.loading.stocks = true
                        this.loading.fetch = true
                        this.loadStockFilterOptions('species')
                            .then((species) => {
                                this.stockSpecies = species
                                this.loadStockFilterOptions('suppliers')
                                    .then((suppliers) => {
                                        this.stockSuppliers = suppliers
                                        this.loadStockFilterOptions('claims')
                                            .then((claims) => {
                                                this.stockClaims = claims
                                                this.loadStocks()
                                            })
                                            .catch((error) => {
                                                this.$toast.error(this.$t('message.error') + ': ' + error,
                                                    {
                                                        classes: ['icon-float-left'],
                                                        icon: 'error_outline'
                                                    }
                                                )
                                            })
                                    })
                                    .catch((error) => {
                                        this.$toast.error(this.$t('message.error') + ': ' + error,
                                            {
                                                classes: ['icon-float-left'],
                                                icon: 'error_outline'
                                            }
                                        )
                                    })
                            })
                            .catch((error) => {
                                this.$toast.error(this.$t('message.error') + ': ' + error,
                                    {
                                        classes: ['icon-float-left'],
                                        icon: 'error_outline'
                                    }
                                )
                            })
                        break;
                    case 'claims':
                        this.loading.stocks = true
                        this.loading.fetch = true
                        this.loadStockFilterOptions('species')
                            .then((species) => {
                                this.stockSpecies = species
                                this.loadStockFilterOptions('suppliers')
                                    .then((suppliers) => {
                                        this.stockSuppliers = suppliers
                                        this.loadStockFilterOptions('products')
                                            .then((products) => {
                                                this.stockProducts = products
                                                this.loadStocks()
                                            })
                                            .catch((error) => {
                                                this.$toast.error(this.$t('message.error') + ': ' + error,
                                                    {
                                                        classes: ['icon-float-left'],
                                                        icon: 'error_outline'
                                                    }
                                                )
                                            })
                                    })
                                    .catch((error) => {
                                        this.$toast.error(this.$t('message.error') + ': ' + error,
                                            {
                                                classes: ['icon-float-left'],
                                                icon: 'error_outline'
                                            }
                                        )
                                    })
                            })
                            .catch((error) => {
                                this.$toast.error(this.$t('message.error') + ': ' + error,
                                    {
                                        classes: ['icon-float-left'],
                                        icon: 'error_outline'
                                    }
                                )
                            })
                        break;
                    case 'types':
                        this.loading.stocks = true
                        this.loading.fetch = true
                        this.loadStockFilterOptions('species')
                            .then((species) => {
                                this.stockSpecies = species
                                this.loadStockFilterOptions('suppliers')
                                    .then((suppliers) => {
                                        this.stockSuppliers = suppliers
                                        this.loadStockFilterOptions('products')
                                            .then((products) => {
                                                this.stockProducts = products
                                                this.loadStockFilterOptions('claims')
                                                    .then((claims) => {
                                                        this.stockClaims = claims
                                                        this.loadStocks()

                                                        if(this.filterOptions.type === 'NSV') {
                                                            this.exportSource = 'stocks';
                                                            this.bundleTitle = this.$t('message.bundle')
                                                            this.piecesTitle = this.$t('message.pcs')
                                                        }
                                                        if(this.filterOptions.type === 'SV') {
                                                            this.exportSource = 'stocks-sliced-veneer';
                                                            this.bundleTitle = this.$t('message.pallet')
                                                            this.piecesTitle = this.$t('message.sheets')
                                                        }
                                                    })
                                                    .catch((error) => {
                                                        this.$toast.error(this.$t('message.error') + ': ' + error,
                                                            {
                                                                classes: ['icon-float-left'],
                                                                icon: 'error_outline'
                                                            }
                                                        )
                                                    })
                                            })
                                            .catch((error) => {
                                                this.$toast.error(this.$t('message.error') + ': ' + error,
                                                    {
                                                        classes: ['icon-float-left'],
                                                        icon: 'error_outline'
                                                    }
                                                )
                                            })
                                    })
                                    .catch((error) => {
                                        this.$toast.error(this.$t('message.error') + ': ' + error,
                                            {
                                                classes: ['icon-float-left'],
                                                icon: 'error_outline'
                                            }
                                        )
                                    })
                            })
                            .catch((error) => {
                                this.$toast.error(this.$t('message.error') + ': ' + error,
                                    {
                                        classes: ['icon-float-left'],
                                        icon: 'error_outline'
                                    }
                                )
                            })
                        break;
                    case 'values':
                        break;
                }
            },
            filterResults () {
                this.loading.filterResults = true
                let _this = this
                setTimeout(function(){
                    _this.loading.filterResults = false
                },700)
            },
            findCurrencyPrecision (val) {
                // let currency = this.allCurrencies.find(c => c.Currency.id == val)
                // return currency?.Currency?.formatPrecision
                return '0,0'
            },
            formatDate,
            formatThisNumber(value,format){
                return numberFormat(value,format)
            },
            handleResize() {
                this.tableHeight = window.innerHeight - ( this.$vuetify.breakpoint.lgAndDown ? 185 : 140);
            },
            loadStocks () {
                if(!this.loading.stocks) this.loading.stocks = true
                if(!this.loading.fetch) this.loading.fetch = true
                let payload = {
                    filterOptions: this.filterOptions,
                }
                this.getAllStocks( payload )
                    .then( tableData => {
                        this.Stocks = tableData.rows
                        this.totalVolumes = tableData.totalVolumes
                    })
                    .catch(() => {
                        this.loading.stocks = false
                        this.loading.fetch = false;
                    })
                    .finally(() => {
                        this.loading.stocks = false
                        this.loading.fetch = false
                        this.tableOptions.page = 1
                    })
            },
            loadStockFilterOptions(filter) {
                return new Promise((resolve, reject) => {
                    const defaultConditions = [
                        {
                            field: 'stockorders.stock_country_id',
                            value: this.filterOptions.office
                        },
                        {
                            field: 'stocks.stockstate_id',
                            value: 3
                        },
                        {
                            field: 'stocks.type',
                            value: this.filterOptions.type
                        }
                    ]
                    let additionalConditions = []
                    if(filter === 'suppliers'){
                        if(this.filterOptions.product != null){
                            additionalConditions.push(
                                {
                                    field: 'stocks.productgroup_id',
                                    value: this.filterOptions.product
                                }
                            )
                        }
                        if(this.filterOptions.species != null){
                            additionalConditions.push(
                                {
                                    field: 'stocks.species_id',
                                    value: this.filterOptions.species
                                }
                            )
                        }
                        if(this.filterOptions.claim != null){
                            additionalConditions.push(
                                {
                                    field: 'stocks.certification_id',
                                    value: this.filterOptions.claim
                                }
                            )
                        }
                    } else if(filter === 'products'){
                        if(this.filterOptions.supplier != null){
                            additionalConditions.push(
                                {
                                    field: 'contracts.supplier_id',
                                    value: this.filterOptions.supplier
                                }
                            )
                        }
                        if(this.filterOptions.species != null){
                            additionalConditions.push(
                                {
                                    field: 'stocks.species_id',
                                    value: this.filterOptions.species
                                }
                            )
                        }
                        if(this.filterOptions.claim != null){
                            additionalConditions.push(
                                {
                                    field: 'stocks.certification_id',
                                    value: this.filterOptions.claim
                                }
                            )
                        }
                    } else if(filter === 'species'){
                        if(this.filterOptions.supplier != null){
                            additionalConditions.push(
                                {
                                    field: 'contracts.supplier_id',
                                    value: this.filterOptions.supplier
                                }
                            )
                        }
                        if(this.filterOptions.product != null){
                            additionalConditions.push(
                                {
                                    field: 'stocks.productgroup_id',
                                    value: this.filterOptions.product
                                }
                            )
                        }
                        if(this.filterOptions.claim != null){
                            additionalConditions.push(
                                {
                                    field: 'stocks.certification_id',
                                    value: this.filterOptions.claim
                                }
                            )
                        }
                    } else if(filter === 'claims'){
                        if(this.filterOptions.supplier != null){
                            additionalConditions.push(
                                {
                                    field: 'contracts.supplier_id',
                                    value: this.filterOptions.supplier
                                }
                            )
                        }
                        if(this.filterOptions.product != null){
                            additionalConditions.push(
                                {
                                    field: 'stocks.productgroup_id',
                                    value: this.filterOptions.product
                                }
                            )
                        }
                        if(this.filterOptions.species != null){
                            additionalConditions.push(
                                {
                                    field: 'stocks.species_id',
                                    value: this.filterOptions.species
                                }
                            )
                        }
                    }
                    api
                        .get('/stocks/' + filter, {
                            params: {
                                conditions: [...defaultConditions, ...additionalConditions]
                            }
                        })
                        .then((response) => {
                            if(response.data.status === 'success'){
                                resolve(response.data.data)
                            } else {
                                reject('error')
                            }
                        })
                        .catch((error) => {
                            reject(error)
                        })
                })
            },
            massEditDialogClosed () {
                this.massEditDialog = false;
            },
            massEditCostDialogClosed () {
                this.massEditCostDialog = false;
            },
            numberFormat,
            openDeleteBundleDialog(bundleId, bundleNo) {
                this.selectedBundleId = bundleId
                this.selectedBundleNo = bundleNo
                this.deleteDialog = true
            },
            openEditBundleDialog (bundleId, bundleNo) {
                this.selectedBundleId = bundleId
                this.selectedBundleNo = bundleNo
                this.editDialog = true
            },
            openMassEditBundleDialog () {
                this.massEditDialog = true
            },
            openMassEditBundleCostDialog () {
                this.massEditCostDialog = true
            },
            openSplitReBundleDialog (bundleId, bundleNo){
                this.selectedBundleId = bundleId
                this.selectedBundleNo = bundleNo
                this.splitDialog = true
            },
            openTotalsDialog () {
                this.totalsDialog = true
            },
            resetFilters () {
                // if()
                // if(this.User){
                //     switch(this.User.country_id){
                //         case 4:
                //             this.filterOptions.office = 4
                //             break
                //         case 1:
                //         default:
                //             this.filterOptions.office = 1
                //             break
                //     }
                // } else {
                //     this.filterOptions.office = 1
                // }
                this.filterOptions.product = null
                this.filterOptions.supplier = null
                this.filterOptions.species = null
                this.filterOptions.claim = null
                this.filterOptions.type = 'NSV'
                this.exportSource='stocks'
                this.bundleTitle = this.$t('message.bundle')
                this.piecesTitle = this.$t('message.pcs'),
                this.loadStocks()
            },
            splitDialogClosed () {
                this.splitDialog = false;
                // this.loadStocks()
            },
            totalsDialogClosed () {
                this.totalsDialog = false;
            },
            updateStockOrder ( stock_id, stockorder_id ) {
                let tab = window.open('/v1/stockorders/update/' + stockorder_id,'_blank')
                tab.name = 'tab_' + Math.floor(Math.random() * 10000)
                this.openedTabs.push(tab.name)
            },
            warehouseChanged() {
                this.loading.stocks = true
                this.loading.fetch = true
                this.selectedBundles = [];
                this.loadStockFilterOptions('suppliers')
                    .then((suppliers) => {
                        this.stockSuppliers = suppliers
                        this.loadStockFilterOptions('products')
                            .then((products) => {
                                this.stockProducts = products
                                this.loadStockFilterOptions('species')
                                    .then((species) => {
                                        this.stockSpecies = species
                                        this.loadStockFilterOptions('claims')
                                            .then((claims) => {
                                                this.stockClaims = claims
                                                this.loadStocks()
                                            })
                                            .catch((error) => {
                                                this.$toast.error(this.$t('message.error') + ': ' + error,
                                                    {
                                                        classes: ['icon-float-left'],
                                                        icon: 'error_outline'
                                                    }
                                                )
                                            })
                                    })
                                    .catch((error) => {
                                        this.$toast.error(this.$t('message.error') + ': ' + error,
                                            {
                                                classes: ['icon-float-left'],
                                                icon: 'error_outline'
                                            }
                                        )
                                    })
                            })
                            .catch((error) => {
                                this.$toast.error(this.$t('message.error') + ': ' + error,
                                    {
                                        classes: ['icon-float-left'],
                                        icon: 'error_outline'
                                    }
                                )
                            })
                    })
                    .catch((error) => {
                        this.$toast.error(this.$t('message.error') + ': ' + error,
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                    })
            }
        },
        watch: {
            'filterOptions.office'(newValue){
                this.stockCurrentWarehouse = newValue
            },
            'filterOptions.type'(newValue){
                this.stockTypeCurrentWarehouse = newValue
            }
        },
        created() {
            if(this.allCurrencies.length === 0) this.getAllCurrencies()
            window.addEventListener('resize', this.handleResize)

            if(this.stockCurrentWarehouse) {
                this.filterOptions.office = this.stockCurrentWarehouse
            } else {
                if (this.User) {
                    switch (this.User.country_id) {
                        case 4:
                            this.filterOptions.office = 4
                            break
                        case 1:
                        default:
                            this.filterOptions.office = 1
                            break
                    }
                } else {
                    this.filterOptions.office = 1
                }
            }

            if(this.stockTypeCurrentWarehouse) {
                this.filterOptions.type = this.stockTypeCurrentWarehouse
                this.filterChanged('types');
            }

            this.loading.stocks = true
            this.loadStockFilterOptions('suppliers')
                .then((suppliers) => {
                    this.stockSuppliers = suppliers
                    this.loadStockFilterOptions('products')
                        .then((products) => {
                            this.stockProducts = products
                            this.loadStockFilterOptions('species')
                                .then((species) => {
                                    this.stockSpecies = species
                                    this.loadStockFilterOptions('claims')
                                        .then((claims) => {
                                            this.stockClaims = claims
                                            this.loadStocks()
                                        })
                                        .catch((error) => {
                                            this.$toast.error(this.$t('message.error') + ': ' + error,
                                                {
                                                    classes: ['icon-float-left'],
                                                    icon: 'error_outline'
                                                }
                                            )
                                        })
                                })
                                .catch((error) => {
                                    this.$toast.error(this.$t('message.error') + ': ' + error,
                                        {
                                            classes: ['icon-float-left'],
                                            icon: 'error_outline'
                                        }
                                    )
                                })
                        })
                        .catch((error) => {
                            this.$toast.error(this.$t('message.error') + ': ' + error,
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                        })
                })
                .catch((error) => {
                    this.$toast.error(this.$t('message.error') + ': ' + error,
                        {
                            classes: ['icon-float-left'],
                            icon: 'error_outline'
                        }
                    )
                })
        },
        mounted() {
            this.handleResize()
        },
        destroyed() {
            window.removeEventListener('resize', this.handleResize)
        }
    }
</script>

<style>
.filter-width-75 {
    width: 75px !important;
    max-width: 75px !important;
}
.filter-width-85 {
    width: 85px !important;
    max-width: 85px !important;
}
.filter-width-100 {
    width: 100px !important;
    max-width: 100px !important;
}
.filter-width-125 {
    width: 125px !important;
    max-width: 125px !important;
}
.filter-width-150 {
    width: 150px !important;
    max-width: 150px !important;
}
.filter-width-130 {
    width: 130px !important;
    max-width: 130px !important;
}
.border-top-1 {
    border-top: 1px solid lightgray !important;
}
.col-0-75 {
    flex: 0 0 6.25%;
    max-width: 6.25%;
}
.col-1-5 {
    flex: 0 0 12.5%;
    max-width: 12.5%;
}
.col-3-5 {
    flex: 0 0 29.16666667%;
    max-width: 29.16666667%;
}
.v-dialog {
    min-width: 200px !important;
}
.v-data-table td {
    padding: 0 3px;
    font-size: 0.8rem !important;
    line-height: 0.8rem;
}
.text-start {
    padding-left: 3px !important;
    padding-top: 3px !important;
    padding-right: 3px !important;
    vertical-align: middle !important;
}
.font-xs {
    font-size: 0.60rem;
}
.mt-5px {
    margin-top: 5px !important;
}
.mt-6px {
    margin-top: 6px !important;
}
.truncate {
    display: inline-block;
    width: 100px !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.v-data-table-header th {
    white-space: nowrap;
}
.v-data-table-header th.force-text-right {
    text-align: right !important;
}
.v-data-table-header tr th:first-child {
    background-color: #c5e1a5 !important;
}
.v-data-table--fixed-header > .v-data-table__wrapper {
    overflow-x: auto;
}
.table-footer {
    height: var(--height);
}
.v-data-table {
    overflow-x: auto;
}
</style>